import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: '/IdentetityCard'//redirect表示重定向，后面跟的是要去的路径
  },
  {
    path: "/identitycard_result",
    name: "identitycard_result",
    component: () => import('@/views/identitycard/identitycard_result.vue')
  },
  {
    path: "/IdentetityCard",
    name: "IdentetityCard",
    // redirect:'/IdentetityCard',
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/identitycard/identitycard_upload.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// router.beforeEach((to,form,next)=>{
// })
export default router